export const HomePageData = {
    title: 'Our priority is you',
    staticIntroduction: `<p>We understand that dealing with the legal system is time-consuming and often frustrating. We can help.</p>`,
    hasImage: true,
    heroImage: {
        mobileImage: 'https://source.unsplash.com/featured/767x664?people',
        tabletImage: 'https://source.unsplash.com/featured/1023x800?people',
        desktopImage: 'https://source.unsplash.com/featured/1600x800?people',
    },
    cta1: {
        txt: `Enquire now`,
        url: `/hello-world`
    },
    cta2: {
        txt: '',
        url: ''
    },
    mediaQuery: '',
    showContact: '',
    breadcrumbs: [
        {
            title: 'Home',
            isActive: true
        }
    ],
    practiceAreaTiles: [
        {
            icon: 'dust-disease',
            title: 'Workplace Injury',
            pageUrl: '#',
            blurb: `<p>If you or a family member is injured at work, it is important to get the right advice about a Workers Compensation claim.</p>`,
        },
        {
            icon: 'contact',
            title: 'Dust disease',
            pageUrl: '#',
            blurb: `<p>If you or a family member has been diagnosed with a disease from workplace exposure to asbestos or silica dust, it is important to get the right advice about compensation.</p>`,
        },
        {
            icon: 'workplace-injury',
            title: 'Traffic accident',
            pageUrl: '#',
            blurb: `<p>If you or a family member is injured on the road in a motor vehicle, public transport, or cycling accident it is important to get the right advice about a TAC claim.</p>`,
        },
        {
            icon: 'dust-disease',
            title: 'Medical negligence',
            pageUrl: '#',
            blurb: `<p>If you or a family member have become injured or unwell as a result of treatment by a medical practitioner, it is important to get the right advice.</p>`,
        },
        {
            icon: 'contact',
            title: 'Public liability ',
            pageUrl: '#',
            blurb: `<p>If you or a family member is injured in a "slip and fall" in a public place, it is important to get the right advice.</p>`,
        },
        {
            icon: 'workplace-injury',
            title: 'Sexual abuse claims',
            pageUrl: '#',
            blurb: `<p>If you or a family member is a survivor of a sexual abuse, it is important to get the right advice.</p>`,
        },
        {
            icon: 'dust-disease',
            title: 'Coronial inquests',
            pageUrl: '#',
            blurb: `<p>When a loved one dies, the grief and trauma can be overwhelming.  We can help.</p>`,
        },
        {
            icon: 'contact',
            title: 'Insurance protection & disability insurance',
            pageUrl: '#',
            blurb: `<p>If you or a family member has suffered an injury, illness or disablement, it is important to get the right advice about your entitlements.</p>`,
        },
        {
            icon: 'workplace-injury',
            title: 'Employment law',
            pageUrl: '#',
            blurb: `<p>If you or a family member has suffered an injury, illness or disablement, it is important to get the right advice about your entitlements.</p>`,
        },
    ]
};
