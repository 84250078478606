import React from 'react';
import StaticLayout from '~layouts/static-layout';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import { HomePageData } from '~data/homepageData';
import sgHomepageContentIconTiles from '~data/sgHomepageContentIconTiles';
import { PracticeAreaLandingData } from '~data/practiceAreaLandingData';
import PageSection from '~2-components/PageSection/PageSection';
import TileContainer from '~2-components/TileContainer/TileContainer';
import ContentIconTile from '~2-components/ContentIconTile/ContentIconTile';
import VideoPanel from '~2-components/VideoPanel/VideoPanel';
import { allanExample } from '~data/videoExample';
import TestimonialCarousel from '~2-components/TestimonialCarousel/TestimonialCarousel';
import { dummyTestimonials } from '~data/dummyTestimonials';

const ExampleHomePage = () => (
    <StaticLayout>
        <RenderedContext.Provider value={HomePageData}>
            <Header />
        </RenderedContext.Provider>

        <div className="page-content">
            <div className="container">
                <PageSection
                    backgroundTheme="grey"
                >
                    <h2>We have a long history of taking on difficult cases and fighting hard to achieve positive outcomes for our clients.</h2>
                    <p>Adviceline Injury Lawyers is a specialist boutique personal injury law firm in Victoria, with particular expertise in work injury, accidents on the road, public place slips and trips and claims of negligence.</p>
                    <p>When you call Adviceline Injury Lawyers, you won’t speak to a secretary or call centre. You’ll speak directly to one of our Accredited Specialists and qualified lawyers who will provide immediate advice about your rights and entitlements.</p>

                    <TileContainer
                        columns="two_columns"
                        homepageLayout
                    >
                        {sgHomepageContentIconTiles.map(tile => (
                            <ContentIconTile
                                key={tile.title}
                                icon={tile.icon}
                                title={tile.title}
                                blurb={tile.blurb}
                                tileLayout={tile.tileLayout}
                                linkUrl={tile.linkUrl}
                                linkTxt={tile.linkTxt}
                            />
                        ))}
                    </TileContainer>
                </PageSection>
                <PageSection>
                    <h2>Why work with us?</h2>
                    <TileContainer>
                        {PracticeAreaLandingData.featuresTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                />
                            );
                        })}
                    </TileContainer>
                </PageSection>

                <VideoPanel {...allanExample} />

                <TestimonialCarousel
                    testimonials={dummyTestimonials}
                />
            </div>
        </div>
    </StaticLayout>
);

export default ExampleHomePage;
